import { HttpParamsPrimitive, ILoaderMetaData, ITimeoutMetaData, ParamsWithMetaData, Primitive } from '@vumaex/ngx-toolbox';
import { HttpParams } from '@angular/common/http';
import { Utils } from './utils';

function hasMetaData(obj: {}): obj is ParamMetaData {
	return obj.hasOwnProperty('meta');
}

export function getMetaData(params: HttpParams): IParamMetaData | undefined {
	return hasMetaData(params) ? params.meta : undefined;
}

export class ParamMetaData extends ParamsWithMetaData<IParamMetaData> {

	public static fromParams(params: HttpParamsPrimitive): ParamMetaData {
		return new ParamMetaData(null, params);
	}

	public static get allCount(): { count: string } { return { count: 'all' }; }

	public withAllCount(): ParamMetaData {
		return this.set('count', 'all');
	}

	public setPage(page: number = 1): ParamMetaData {
		return this.set('page', page);
	}

	public setCount(count: number = 10): ParamMetaData {
		return this.set('count', count);
	}

	public setQuery(query: string = ''): ParamMetaData {
		return this.set('q', query);
	}

	public setRefresh(refreshRequired: boolean): ParamMetaData {
		return this.set('refresh', refreshRequired);
	}

	public setDefaultParams(params: any): ParamMetaData {
		return this.setQuery(params.query).setPage(params.page).setCount(params.count);
	}

	// HttpParams is immutable - have to create a new object
	public set(param: string, value: Primitive): ParamMetaData {
		this.params = { ...(this.params || {}), [param]: Utils.isEmpty(value) ? '' : value };
		return new ParamMetaData(this.meta, this.params);
	}

}

export interface IParamMetaData extends ILoaderMetaData, ITimeoutMetaData {
	// Either:
	//  .namespace - for error handling specifics
	//  .false - to disable error handling
	handleError?: string | boolean;

	authToken?: AuthConfig
}

export type AuthConfig = AuthType | string;

export enum AuthType {
	USER, PROVIDER, NONE, EITHER,
}
