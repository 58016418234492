import { AexNgToolboxModule, DEFAULT_LOADER_CONFIG, MockModule, NavigationService } from '@vumaex/ngx-toolbox';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { environment } from '@env/environment';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbModule, NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { NgxPaginationModule } from 'ngx-pagination';
import { SelectDropDownModule } from 'ngx-select-dropdown';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ToastContainerModule, ToastrModule } from 'ngx-toastr';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { MockRegistrations } from '../mock/mock';
import { AppComponent } from './app.component';
import { APP_ROUTES } from './app.routing';
import { ConfigService } from './root-services/config.service';
import { AuthInterceptor, ErrorInterceptor, GlobalErrorHandler, ApiInterceptor, TOASTER_CONFIG } from './tools/public-api';
import { FlexModule } from '@angular/flex-layout';

@NgModule({
	declarations: [
		AppComponent,
	],
	imports: [
		RouterModule.forRoot(APP_ROUTES),
		HttpClientModule,
		AexNgToolboxModule.forChild(),
		MockModule.forRoot({
			enabled: environment.mocksEnabled,
			serverUrls: [
				environment.serverUrl,
				environment.ispServerUrl,
				environment.ipPoolServerUrl,
			],
		}),
		ToastrModule.forRoot(TOASTER_CONFIG),
		NgxUiLoaderModule.forRoot(DEFAULT_LOADER_CONFIG),
		NgbModule,
		NgxSpinnerModule,
		SelectDropDownModule,
		ReactiveFormsModule,
		BsDatepickerModule.forRoot(),
		BrowserModule,
		BrowserAnimationsModule,
		TimepickerModule.forRoot(),
		FontAwesomeModule,
		NgbPaginationModule,
		NgxPaginationModule,
		FlexModule,
		ToastContainerModule,
	],
	providers: [
		NavigationService,
		ConfigService,
		{provide: APP_INITIALIZER, deps: [MockRegistrations], useFactory: () => () => Promise.resolve(), multi: true},
		{
			provide: APP_INITIALIZER, deps: [ConfigService],
			useFactory: (startUp: ConfigService) => () => startUp.loadAppConfig().toPromise(), multi: true,
		},
		{provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true},
		{provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
		{provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
		{provide: ErrorHandler, useClass: GlobalErrorHandler},
	],
	bootstrap: [AppComponent],
})
export class AppModule {}
