import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError, of, timer } from 'rxjs';
import { catchError, concatMap, retryWhen, mergeMap } from 'rxjs/operators';
import { HttpErrorService } from '../root-services/http-error.service';
import { getMetaData } from './param-meta-data';
import { NO_RESPONSE_STATUS, REQUEST_TIMEOUT, GATEWAY_TIMEOUT } from './consts';


const DELAY_TIME_INCREASE_CONST = 2;
const DELAY_TIME = 1000;
@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

	private readonly requestRetryLimit: number = 3;

	constructor(
		private readonly httpError: HttpErrorService,
	) { }

	public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return next.handle(request).pipe(
			retryWhen(
				error => this.retryRequest(error, this.requestRetryLimit),
			),
			catchError(
				(error: HttpErrorResponse) => {
					const meta = getMetaData(request.params);

					let lError = error;

					if (!meta || meta.handleError !== false)
						lError = this.httpError.handleError(lError, meta && meta.handleError);
					return throwError(lError);
				},
			),
		);
	}

	public retryRequest(error: Observable<any>, requestRetryLimit: number): Observable<any> {
		return error.pipe(
			concatMap(
				(aHttpErrorResponse: HttpErrorResponse, count: number) => {
					if (count <= requestRetryLimit)
						switch (aHttpErrorResponse.status) {
							case NO_RESPONSE_STATUS: // no response received from server
							case REQUEST_TIMEOUT:
							case GATEWAY_TIMEOUT:
								// Delay in milliseconds
								const delayTime = Math.pow(DELAY_TIME_INCREASE_CONST, count + 1) * DELAY_TIME;
								return this.delayRequestRetry(aHttpErrorResponse, delayTime);
							default:
								break;
						}

					return throwError(aHttpErrorResponse);
				},
			),
		);
	}

	public delayRequestRetry(aHttpErrorResponse: HttpErrorResponse, aDelayTime: number): Observable<any> {
		return of(aHttpErrorResponse).pipe(
			mergeMap(
				() => timer(aDelayTime),
			),
		);
	}
}

