import { HttpErrorResponseExt } from '@vumaex/ngx-toolbox';
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BAD_REQUEST, GATEWAY_TIMEOUT, INTERNAL_SERVER_ERROR, NOT_FOUND, UNAUTHORIZED } from 'http-status-codes';
import { ToastrService } from 'ngx-toastr';

@Injectable({ providedIn: 'root' })
export class HttpErrorService {

	constructor(
		private readonly toast: ToastrService,
	) { }

	private static getBadRequestErrors(error: IServerError | string): string {
		const lError: IServerError = typeof error === 'string' ? JSON.parse(error) : error;
		const errorList = lError.full_errors;
		return errorList && Array.isArray(errorList) ? errorList[0] : lError.error;
	}

	private static getErrorMsg(error: HttpErrorResponse, namespace: string): string {

		const messages: { [category: string]: { [code: number]: string | StringFunction } } = {
			authentication: {
				[BAD_REQUEST]: 'Could not complete the request. Please try again.',
				[UNAUTHORIZED]: 'User unauthorized. Contact Administrator',
				[GATEWAY_TIMEOUT]: 'Server timeout. Please try again.',
			},
			area: {
				[NOT_FOUND]: 'Area not found.',
			},
			workOrderAsset: {
				[BAD_REQUEST]: 'Assets could not be saved. Please try again.',
				[NOT_FOUND]: null,
			},
			serviceAsset: {
				[BAD_REQUEST]: 'Assets could not be saved. Please try again.',
				[NOT_FOUND]: null,
			},
			deviceManagement: {
				[BAD_REQUEST]: 'Device could not be updated. Please try again.',
				[NOT_FOUND]: 'Service not found',
			},
			kml: {
				[BAD_REQUEST]: 'KML file is invalid',
				[NOT_FOUND]: 'KML not found',
				[INTERNAL_SERVER_ERROR]: 'KML file is invalid',
			},
			premise: {
				[BAD_REQUEST]: 'Premise could not be updated. Please try again.',
				[NOT_FOUND]: 'Premise or Service not found',
			},
			service: {
				[BAD_REQUEST]: 'Could not complete the request. Please try again.',
				[NOT_FOUND]: 'Service not found',
			},
			speedTest: {
				[BAD_REQUEST]: 'Could not create the Speed Test. Please try again after 24 Hours.',
				[GATEWAY_TIMEOUT]: 'Server timeout. Please try again.',
				[NOT_FOUND]: null,
			},
			solarStatus: {
				[BAD_REQUEST]: 'Plant Data could not be refreshed',
				[NOT_FOUND]: 'Plant Not Found',
			},
			workOrder: {
				[BAD_REQUEST]: 'Work order could not be created. Please try again.',
				[NOT_FOUND]: 'Work order not found',
			},
			serviceStatus: {
				[BAD_REQUEST]: 'Device Could Not Be Refreshed. Please try again.',
				[NOT_FOUND]: 'Error - Please try again.',
			},
			wifiStatus: {
				[BAD_REQUEST]: 'Wi-Fi Could Not Be Refreshed. Please try again.',
				[NOT_FOUND]: 'Wi-Fi Information Not Found.',
			},
			default: {
				[NOT_FOUND]: 'Resource not found',
				[INTERNAL_SERVER_ERROR]: 'Server Error. Please try again',
				[GATEWAY_TIMEOUT]: 'Server timeout. Please try again',
				[UNAUTHORIZED]: 'User unauthorized. Contact Administrator',
			},
		};

		let message = messages[namespace] && messages[namespace][error.status];
		if (error.status === BAD_REQUEST) {
			const serverError = HttpErrorService.getBadRequestErrors(error.error);
			message = typeof message === 'function' ? message(serverError) : serverError || message;
		}
		message = message === undefined ? messages[DEFAULT_NAMESPACE][error.status] : message;
		return typeof message === 'function' ? message() : message;
	}

	public handleError(error: HttpErrorResponse, namespace: string | boolean): HttpErrorResponse {
		const lNamespace = typeof namespace === 'string' ? namespace : DEFAULT_NAMESPACE;
		const message = HttpErrorService.getErrorMsg(error, lNamespace);
		if (message !== undefined) {
			if (message)
				this.toast.error(message);
			return new HttpErrorResponseExt(error, message, true);
		} else
			return error;
	}

	public handleWarning(error: HttpErrorResponse, namespace: string | boolean): HttpErrorResponse {
		const lNamespace = typeof namespace === 'string' ? namespace : DEFAULT_NAMESPACE;
		const message = HttpErrorService.getErrorMsg(error, lNamespace);
		if (message !== undefined) {
			if (message)
				this.toast.warning(message);
			return new HttpErrorResponseExt(error, message, true);
		} else
			return error;
	}

}

type StringFunction = (message?: string) => string;

const DEFAULT_NAMESPACE = 'default';

interface IServerError {
	error: string
	full_errors: string[]
}
