import { IPagedResponse } from '@vumaex/ngx-toolbox';
import { ICivilStatusSummaryType, IStatusResult } from '@app/root-services/types';
import { IConfiguration, IDeviceHistory, INetworkPower, IOperationalStatus, IServiceStatus, IWifiStatus } from '@tools/root-services/types';

export const CIVIL_STATUS: IStatusResult = {
	items: [
		{
			gate_id: 4,
			gate_name: 'Gate - 4',
			task_id: 13,
			task_name: 'HOC',
			status_id: 14,
			status_name: 'Connected',
		},
	],
	area_id: '1648292B-8EDD-4E8C-82E2-00E577E1CDF5',
	tasks: [
		{
			id: 72,
			area_type_id: 7,
			area_type: 'MDU',
			task_id: 14,
			task: 'MDU On Hold',
			gate_id: 1,
			gate: 'Gate - 1',
			area_status_id: '10',
			area_status: 'Show Your Interest',
			order: 1,
			customer_id: '243',
			customer: '243',
			gate_transition_customer_id: '244',
			gate_transition_customer: '244',
			created_at: new Date('2020-02-24 11:55:43 +0200'),
			updated_at: new Date('2020-02-24 11:55:43 +0200'),
		},
	],
	page: 1,
	count: 1,
	total: 1,
};

export const CIVIL_STATUS_SUMMARY_TYPES: IPagedResponse<ICivilStatusSummaryType> = {
	items: [
		{
			id: 4,
			name: 'Portfolio',
			count: 32,
		},
		{
			id: 5,
			name: 'Building',
			count: 185,
		},
		{
			id: 7,
			name: 'Managing Agent',
			count: 2,
		},
	],
	page: 1,
	count: 3,
	total: 3,
};

export const OPERATIONAL_STATUS: IOperationalStatus = {
	serial_number:'UBNTB9068731',
	last_updated:'2021-10-22T10:16:13+02:00',
	service:'up', pop_id:null,
	pop_name:'ZA-ZN-WES-OLT2',
	pop_description:null,
	pop_status:null,
	alerts:['TX Light Levels Abnormal'],
	alarms:[],
	populated_ports:null,
};

export const DEVICE_HISTORY: IPagedResponse<IDeviceHistory> = {
	items:
			[
					{
						tx_status:'Abnormal',
						sfp_light_level:null,
						added:'2021-10-22T10:16:13+02:00',
						tx_light_level:0.426,
						alarms:[],
						rx_light_level:-25.528,
						oper_status:'Up',
						rx_status:'Normal',
						reference:'UBNTB9068731',
						alerts:['TX Light Levels Abnormal'],
						olt_name:'ZA-ZN-WES-OLT2',
						ont_distance:377000,
						vendor:'ubiquiti_v2',
						olt:'ZA-ZN-WES-OLT2',
						sfp_status:'Unknown',
						name:'546c9f55-79e9-4f54-b848-19b5bef9cd5c',
						admin_status:'Up',
						model:null, status:'Up',
					},
					{
						tx_status:'Abnormal',
						sfp_light_level:null,
						added:'2021-07-13T18:42:43+02:00',
						tx_light_level:0.588, alarms:[],
						rx_light_level:-26.02,
						oper_status:'Up',
						rx_status:'Normal',
						reference:'UBNTB9068731',
						alerts:['TX Light Levels Abnormal'],
						olt_name:'ZA-ZN-WES-OLT2',
						ont_distance:376000,
						vendor:'ubiquiti_v2',
						olt:'ZA-ZN-WES-OLT2',
						sfp_status:'Unknown',
						name:'546c9f55-79e9-4f54-b848-19b5bef9cd5c',
						admin_status:'Up',
						model:null, status:'Up',
					},
			],
	page:1,
	count:2,
	total:2,
};

export const SERVICE_STATUS: IServiceStatus = {
	service_port:'1',
	network:null,
	pop:'ZA-ZN-WES-OLT2',
	serial_number:'UBNTB9068731',
	ip_address:null,
	isp_modem_mac:null,
	gpon_status:'up',
	nni_mac:'success',
	cpe:'ubiquiti_v2',
	model:null,
	configuration:'Present - Auto',
	devices_connected:'active',
	lease_expiry:null,
	pppoe_status:'down',
	modem_port_speed:null,
	modem_connected:null,
	object_status:null,
	order_status:null,
	status_last_updated:'2021-10-22T13:31:35+02:00',
};

export const NETWORK_POWER: INetworkPower = {
	olt_port:'546c9f55-79e9-4f54-b848-19b5bef9cd5c',
	distance:0.38,
	rx_status:'Normal',
	rx_power:-25.53,
	sfp_status:'Unknown',
	sfp_power:null,
	tx_power:0.43,
	tx_status:'Abnormal',
	switch:null,
	sfp_sync_speed:null,
	switch_port:null,
	lease_expiry:null,
};

export const DEVICE_CONFIG: IConfiguration = {
	vlans:'2706/0',
	download_speed:null,
	upload_speed:null,
	ont_port:1,
	service_provider:'Ballito ISP',
	speeds_success: false,
	vlans_success: false,
};

export const WIFI_STATUS: IWifiStatus = {
	state:null,
	ssid:null,
	mode:null,
	frequency:null,
	devices:[],
	last_updated:null,
	devices_last_updated:null,
};
