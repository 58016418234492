import { ServiceStatus } from '@tools/lib/consts';
import { IProduct } from '@app/root-services/types';

export class PlatformError {
	constructor(public cause?: any) {}
}

export interface IAuthResponse {
	auth_token: string
}

export interface ICustomer {
	id: string
	premises: Partial<IPremises>[]
	first_name: string
	mobile_number: string
	email: string
	last_name: string
	billing_address: string
	billing_city: string
	billing_country: string
	billing_postal_code: string
	billing_province: string
	billing_suburb: string
	business_name: string
	home_number: string
	identity_number: string
	identification_type_id: number
	parent_id: string
	physical_address: string
	physical_city: string
	physical_country: string
	physical_postal_code: string
	physical_province: string
	physical_suburb: string
	portal_user_id: string
	registration_number: string
	username: string
	vat_number: string
	work_number: string
	password?: string
	full_name: string
	created_at: string
	updated_at: string
}

export interface IRole {
	id: string,
	name: string,
	description: string,
}

export interface IPremises {
	id?: string
	latitude: number
	longitude: number
	street_number: string
	street_name: string
	suburb: string
	city: string
	country_code: string
	postal_code: string
	province: string
	intersection: string
	type_id?: string
	customer_id: string
	name: string
	full_address: string
	address_string?: string
	mdu_number: string
	mdu_number_int: number
	mdu_name: string
	external_reference: string
	asset_reference: string
	created_at: string
	updated_at: string
}

export interface IPremisesUpdate {
	premise: Partial<IPremises>
	update_for_empty_customer?: boolean
}

export interface IInstaller {
	name: string
	id: string
	assigned_company: string
	parent_id: string
	registration_number: string
	support_number: string
	website: string
	email: string
	active: boolean
	enabled: boolean
}

export interface ITokenResult {
	iss?: string;
	iat?: number;
	uid?: string;
	claims?: string[];
	exp?: number;
	alg?: string;
}

export interface IAuthResponse {
	auth_token: string
}

export interface ITableSettings {
	sortBy?: string,
	searchBy?: string,
	desc?: boolean,
	start?: number,
	end?: number,
	searchString?: string,
}

export interface IAssets{
	enabled: boolean,
	attributes: IAsset[]
}

export interface IAsset{
	required: boolean,
	description: string
}

export interface IIspFnoWorkOrder {
	isp_work_order: IWorkOrder,
	fno_work_order: IWorkOrder,
	product: Partial<IProduct>
}

export interface IWorkOrder{
	id: string,
	type_id: number,
	account_id: string,
	reference: string,
	on_hold: boolean,
	completed_date: string,
	close_date: string,
	schedule_date: string,
	schedule_time: string,
	external_reference: string,
	category: string,
	updated_at: string,
	created_at: string,
	status_id: string,
	assigned: string,
	assigned_id: string,
	assigned_user_id: string,
	status: string,
	type: string,
	service_id: string,
	last_comment: string,
	created_by: string
	closed: boolean
	installer_id: string
	comment: string
	last_updated_days: number
	days_open: number,
	description: string,
	summary: string
}

export interface IMacAddress {
	mac_address: string
}

export interface IIpAddress {
	ip: string
}

export interface ICoverageRegions {
	id?: number,
	region_id?: number,
	region_name?: string,
	coverage_id?: number,
	created_at?: string,
	updated_at?: string,
}

export interface ICoverage {
	id?: number,
	name?: string,
	created_at?: string,
	updated_at?: string,
}

export interface IAexRegion {
	id: number,
	name: string,
	latitude: number,
	longitude: number,
	created_at: string,
	updated_at: string,
	zoom_level?: number,
}

export interface ICoverageInterconnectsList {
	id: number,
	name: string,
	created_at: string,
	updated_at: string,
	coverage_regions_count: number,
	interconnects_count: number,
	coverage_providers_count: number,
}

export interface ICoverageInterconnects {
	id: number,
	name: string,
	created_at: string,
	updated_at: string,
	coverage_regions: ICoverageRegions[],
}

export interface IProviderCoverage {
	id: number,
	provider_id: number,
	coverage_id: number,
	created_at: string,
	updated_at: string,
	provider_name?: string,
	coverage_name?: string,

	coverage: ICoverageInterconnectsList,
	provider: IProvider,
}

export interface IProvider {
	id: string,
	name: string,
	guid: string,
	registration_number: string,
	support_number: string,
	website: string,
	email: string,
	active: boolean,
	created_at: string,
	updated_at: string,
}

export interface ICoverageProvider {
	id?: number,
	provider_id?: number,
	provider?: IProvider,
	coverage_id?: number,
	coverage?: ICoverageInterconnectsList,
}

export interface IRegionOlt {
	id?: number,
	region_id?: number,
	region_name?: string,
	network_node_id?: number,
	network_node_name?: string,
	created_at?: string,
	updated_at?: string,

	region?: IAexRegion,
	network_node?: INetworkNode,

}

export interface INetworkNode {
	id: number,
	network_id: number,
	operator_id: number,
	type: string,
	node_type: string,
	parent_id?: number,
	children?: string,
	identifier: string,
	status: string,
	reference: string,
	description: string,
	updated_at: string,
	created_at: string,

	network_information: INetworkInformation,
}

export interface INetworkInformation {
	DT: string,
	DVER: string,
}

export interface IDevice {
	id: number,
	network_id: number,
	operator_id: number,
	type: string,
	node_type: string,
	parent_id: number,
	children?: string,
	identifier: string,
	status: string,
	reference: string,
	description: string,
	updated_at: string,
	created_at: string

	information: IDeviceInformation
}

export interface IDeviceInformation {
	Handle_Type: string,
	Handle_Id: string,
	Device_Id: string,
	Device_Type: string,
	name: string,
	deviceIP: string,
	state: string,
	active: string
}

export interface IGoogleCoOrdinates{
	googleMarkers: {
		lat: number,
		lng: number,
	}[],
	googlePolygons: {
		lat: number,
		lng: number,
	}[][]
}

export interface IStatusBlockInformation {
	label: string
	value: any
	disabled?: boolean
	titlecase?: boolean
	type?: string
	highlightLine?: string
	length?: number
}

export interface IStatusAlarmInformation {
	message: string
	status: string
}

export interface IOperationalStatus {
	service: string
	last_updated: string
	pop_id: string
	pop_description: string
	pop_status: string
	pop_name: string
	serial_number: string
	populated_ports: number[]
	alerts: string[]
	alarms: string[]
}

export interface IDeviceHistory {
	tx_status: string
	sfp_light_level: number
	added: string
	tx_light_level: number
	alarms: string[]
	rx_light_level: number
	oper_status: string
	rx_status: string
	reference: string
	alerts: string[]
	olt_name: string
	ont_distance: number
	vendor: string
	olt: string
	sfp_status: string
	name: string
	admin_status: string
	model: string,
	status: string
}

export interface IServiceStatus {
	gpon_status: string
	pppoe_status: string
	service_port: number | string
	network: string
	pop: string
	serial_number: string
	ip_address: string
	isp_modem_mac: string
	nni_mac: string
	cpe: string
	model: string
	configuration: string
	devices_connected: string
	lease_expiry: string
	modem_port_speed: number
	modem_connected: number
	object_status: string
	order_status: string
	status_last_updated: string
}

export interface INetworkPower {
	olt_port: string
	last_updated?: string
	distance:number
	rx_power: number
	rx_status: string
	sfp_power: number
	sfp_status: string
	tx_power: number
	tx_status: string
	switch: string
	sfp_sync_speed: string
	switch_port: number
	lease_expiry: string
}

export interface IConfiguration {
	vlans: string
	download_speed: number
	upload_speed: number
	ont_port: number
	service_provider: string
	speeds_success: boolean
	vlans_success: boolean
	last_updated?: string
}

export interface IWifiDevice {
	ssid: string
	mac_address: string
	current_down_speed: number
	current_up_speed: number
	signal: number
	noise: number
	signal_to_noise: number
	signal_quality: number
}

export interface IWifiStatus {
	state: string
	ssid: string
	mode: string
	frequency: string
	devices: IWifiDevice[]
	last_updated: string
	devices_last_updated: string
}

export interface IAsset {
	created_at: string
	deleted_at: string
	work_order_id?: string
	service_id?: string
	attributes: any[]
}

export interface IAssetRequirement {
	enabled: boolean;
	work_order_id?: string;
	service_id?: string
	attributes: IAssetAttribute[] | any;
}

export interface IAssetAttribute {
	required?: boolean;
	description: string;
	type?: string;
	value?: string | number;
}

export interface IServiceAssetsCollection {
	serviceType: IAssetAttribute[],
	darkType: IAssetAttribute[],
	waveType: IAssetAttribute[]
}

export interface IBoltOnService {
	id: string
	parent_id: string;
	bolt_on_id: string;
	parent_service_name: string;
	parent_service_provider_id: string;
	parent_service_provider: string;
	bolt_on_service_name: string;
	bolt_on_service_provider_id: string;
	bolt_on_service_provider: string;
}

export interface IBoltOnProduct {
	id: string;
	parent_id: string;
	bolt_on_id: string;
	default_work_order_type_id: number;
	parent_product_name: string;
	bolt_on_product_name: string;
}

// NOSONAR - Class implements the interfaces which is snake-case fields
export class ServiceStatusViewModel {

	private fullServiceStatus : IFullServiceStatus = null;
	private fullServicePortConfig : IFullServicePortConfiguration = null;
	private statusConfig : ISalesForceServiceStatus = null;
	private activeServiceConfig: INetworkService = null;
	private services: INetworkService[] = null;
	private readonly Waiting = '';

	//#region --- IFullServicePortConfiguration ---

	public get service_port() : string { // NOSONAR - Class implements the interfaces which is snake-case fields
		return (this.statusConfig === null) ? this.Waiting : this.statusConfig?.interface?.name;
	}
	public get pppoe_username(): string { // NOSONAR - Class implements the interfaces which is snake-case fields
		return (this.statusConfig === null) ? this.Waiting : this.statusConfig?.access?.pppoe_username;
	}
	public get ip_address() : string { // NOSONAR - Class implements the interfaces which is snake-case fields
		return (this.fullServicePortConfig === null) ? this.Waiting : this.fullServicePortConfig?.ip_address;
	}
	public get isp_modem_mac() : string { // NOSONAR - Class implements the interfaces which is snake-case fields
		return (this.statusConfig === null) ? this.Waiting : this.statusConfig?.interface?.mac_address;
	}
	public get nni_mac() : string { // NOSONAR - Class implements the interfaces which is snake-case fields
		return (this.statusConfig === null) ? this.Waiting : this.statusConfig?.interface?.mac_status;
	}
	public get configuration() : string {
		return (this.fullServicePortConfig === null) ? this.Waiting : this.fullServicePortConfig?.configuration;
	}
	public get devices_connected() : string { // NOSONAR - Class implements the interfaces which is snake-case fields
		return (this.statusConfig === null) ? this.Waiting : this.getDevicesConnectedAsString();
	}
	public get pppoe_status() : string { // NOSONAR - Class implements the interfaces which is snake-case fields
		return (this.fullServicePortConfig === null) ? this.Waiting : this.fullServicePortConfig.pppoe_status;
	}
	public get order_status() : string { // NOSONAR - Class implements the interfaces which is snake-case fields
		return (this.statusConfig === null) ? this.Waiting : this.statusConfig?.interface?.object_status;
	}
	public get last_updated() : string { // NOSONAR - Class implements the interfaces which is snake-case fields
		return (this.fullServiceStatus === null) ? this.Waiting : this.fullServiceStatus?.last_updated;
	}
	public get pop_id() : string { // NOSONAR - Class implements the interfaces which is snake-case fields
		return (this.fullServicePortConfig === null) ? this.Waiting : this.fullServicePortConfig?.pop_id.toString();
	}
	public get pop_description() : string { // NOSONAR - Class implements the interfaces which is snake-case fields
		return (this.fullServicePortConfig === null) ? this.Waiting : this.fullServicePortConfig.pop_description;
	}
	public get pop_status() : string { // NOSONAR - Class implements the interfaces which is snake-case fields
		return (this.statusConfig === null) ? this.Waiting : this.statusConfig?.parent?.status;
	}
	public get vlans() : string { // NOSONAR - Class implements the interfaces which is snake-case fields
		if (this.statusConfig === null)
			return this.Waiting;
		else
			if (this.statusConfig?.interface?.vlan === null || this.statusConfig?.interface?.slan === null)
				return null;
			else
				return `${this.statusConfig?.interface?.vlan}/${this.statusConfig?.interface?.slan}`;
	}
	public get download_speed() : number { // NOSONAR - Class implements the interfaces which is snake-case fields
		return (this.statusConfig === null) ? 0 : this.statusConfig?.interface?.down_speed;
	}
	public get upload_speed() : number { // NOSONAR - Class implements the interfaces which is snake-case fields
		return (this.statusConfig === null) ? 0 : this.statusConfig?.interface?.up_speed;
	}
	public get ont_port() : number { // NOSONAR - Class implements the interfaces which is snake-case fields
		return (this.statusConfig === null) ? 0 : this.statusConfig?.interface?.port;
	}
	public get service_provider() : string { // NOSONAR - Class implements the interfaces which is snake-case fields
		return (this.activeServiceConfig === null) ? this.Waiting : this.activeServiceConfig?.provider;
	}
	public get speeds_success() : boolean { // NOSONAR - Class implements the interfaces which is snake-case fields
		return (this.activeServiceConfig === null) ? false : this.getSpeedSuccess();
	}
	public get vlans_success() : boolean { // NOSONAR - Class implements the interfaces which is snake-case fields
		return (this.fullServicePortConfig === null) ? false : this.fullServicePortConfig?.vlans_success;
	}
	//#endregion --- IFullServicePortConfiguration ---

	//#region --- IFullServiceStatus ---
	public get network() : string {
		return (this.statusConfig === null) ? this.Waiting : this.statusConfig?.interface?.network_type;
	}
	public get pop() : string {
		return (this.statusConfig === null) ? this.Waiting : this.statusConfig?.parent?.name;
	}
	public get serial_number() : string { // NOSONAR - Class implements the interfaces which is snake-case fields
		return (this.statusConfig === null) ? this.Waiting : this.statusConfig?.interface?.reference;
	}
	public get gpon_status() : string { // NOSONAR - Class implements the interfaces which is snake-case fields
		return (this.fullServiceStatus === null) ? this.Waiting : this.fullServiceStatus?.gpon_status;
	}
	public get cpe() : string { // NOSONAR - Class implements the interfaces which is snake-case fields
		return (this.fullServiceStatus === null) ? this.Waiting : this.fullServiceStatus?.cpe;
	}
	public get model() : string {
		if (this.statusConfig === null)
			return this.Waiting;
		else
			if (this.statusConfig?.device?.model)
				return this.statusConfig?.device?.model;
			else
				return this.statusConfig?.parent?.model;
	}
	public get lease_expiry() : string { // NOSONAR - Class implements the interfaces which is snake-case fields
		return (this.statusConfig === null) ? this.Waiting : this.statusConfig?.interface?.lease_time;
	}
	public get modem_port_speed() : number { // NOSONAR - Class implements the interfaces which is snake-case fields
		return (this.fullServiceStatus === null) ? 0 : this.fullServiceStatus?.modem_port_speed;
	}
	public get modem_connected() : number { // NOSONAR - Class implements the interfaces which is snake-case fields
		return (this.fullServiceStatus === null) ? 0 : this.fullServiceStatus?.modem_connected;
	}
	public get object_status() : string { // NOSONAR - Class implements the interfaces which is snake-case fields
		return (this.statusConfig === null) ? this.Waiting : this.statusConfig?.interface?.object_status;
	}
	// Two last_updated dates: Presently this one is not used. Used is from FullServiceStatusConfiguration
	public get status_last_updated() : string	{ // NOSONAR - Class implements the interfaces which is snake-case fields
		return (this.fullServiceStatus === null) ? this.Waiting : this.fullServiceStatus.last_updated;
	}
	public get service() : string {
		return (this.fullServiceStatus === null) ? this.Waiting : this.fullServiceStatus.service;
	}
	public get pop_name() : string { // NOSONAR - Class implements the interfaces which is snake-case fields
		return (this.statusConfig === null) ? this.Waiting : this.statusConfig?.parent?.name;
	}
	public get alerts(): string[] {
		return (this.statusConfig === null) ? null : this.statusConfig.alerts;
	}
	public get alarms(): string[] {
		return (this.statusConfig === null) ? null : this.statusConfig.alarms;
	}
	public get populated_ports() : number[] { // NOSONAR - Class implements the interfaces which is snake-case fields
		return (this.fullServiceStatus === null) ? null : this.fullServiceStatus.populated_ports;
	}
	public get olt_port() : string { // NOSONAR - Class implements the interfaces which is snake-case fields
		return (this.statusConfig === null) ? this.Waiting : this.statusConfig?.interface?.name;
	}
	public get distance() : number { // NOSONAR - Class implements the interfaces which is snake-case fields
		return (this.fullServiceStatus === null) ? 0 : this.fullServiceStatus?.distance;
	}
	public get rx_status() : string { // NOSONAR - Class implements the interfaces which is snake-case fields
		return (this.fullServiceStatus === null) ? this.Waiting : this.fullServiceStatus?.rx_status;
	}
	public get rx_power() : number { // NOSONAR - Class implements the interfaces which is snake-case fields
		return (this.fullServiceStatus === null) ? 0 : this.fullServiceStatus?.rx_power;
	}
	public get sfp_status() : string { // NOSONAR - Class implements the interfaces which is snake-case fields
		return (this.fullServiceStatus === null) ? this.Waiting : this.fullServiceStatus?.sfp_status;
	}
	public get sfp_power() : number { // NOSONAR - Class implements the interfaces which is snake-case fields
		return (this.fullServiceStatus === null) ? 0 : this.fullServiceStatus?.sfp_power;
	}
	public get tx_power() : number { // NOSONAR - Class implements the interfaces which is snake-case fields
		return (this.fullServiceStatus === null) ? 0 : this.fullServiceStatus?.tx_power;
	}
	public get tx_status() : string { // NOSONAR - Class implements the interfaces which is snake-case fields
		return (this.fullServiceStatus === null) ? this.Waiting : this.fullServiceStatus?.tx_status;
	}
	public get switch() : string {
		return (this.fullServiceStatus === null) ? this.Waiting : this.fullServiceStatus?.switch;
	}
	public get sfp_sync_speed() : string { // NOSONAR - Class implements the interfaces which is snake-case fields
		return (this.fullServiceStatus === null) ? this.Waiting : this.fullServiceStatus?.sfp_sync_speed;
	}
	public get switch_port() : number { // NOSONAR - Class implements the interfaces which is snake-case fields
		return (this.fullServiceStatus === null) ? 0 : this.fullServiceStatus?.switch_port;
	}
	//#endregion --- IFullServiceStatus ---

	private getDevicesConnectedAsString(): string {
		if ((this.fullServicePortConfig.devices_connected === null) || (this.fullServicePortConfig.devices_connected === 0))
			return 'None';
		else
			return this.fullServicePortConfig.devices_connected.toString();
	}

	private getSpeedSuccess(): boolean {
		const activeServicesSpeedMatch = this.services.filter(service => service.status === ServiceStatus.ACTIVE && this.speedsMatch(service));
		if (activeServicesSpeedMatch.length > 0)
			return true;
		else
			return this.speedsMatch(this.activeServiceConfig);
	}

	private speedsMatch(service: INetworkService): boolean {
		return service.down_speed === this.download_speed?.toString() && service.up_speed === this.upload_speed?.toString();
	}

	public setViewModel(
		fullServicePortConfig: IFullServicePortConfiguration,
		fullServiceStatus: IFullServiceStatus,
		status : ISalesForceServiceStatus,
		service: INetworkService,
		services: INetworkService[],
	) : void {
		this.fullServicePortConfig = fullServicePortConfig;
		this.fullServiceStatus = fullServiceStatus;
		this.statusConfig = status;
		this.activeServiceConfig = service;
		this.services = services;
	}
}

export interface IFullServicePortConfiguration {
	service_port : number;
	ip_address: string;
	isp_modem_mac: string;
	nni_mac: string;
	configuration: string;
	devices_connected: number;
	pppoe_status: string;
	order_status: string;
	last_updated: string;
	pop_id: number;
	pop_description: string;
	pop_status: string;
	vlans: string;
	download_speed: number;
	upload_speed: number;
	ont_port: number;
	service_provider: string;
	speeds_success: boolean;
	vlans_success: boolean;
}

export interface IFullServiceStatus {
	network: string;
	pop: string;
	serial_number: string;
	gpon_status: string;
	cpe: string;
	model: string;
	lease_expiry: string;
	modem_port_speed: number;
	modem_connected: number;
	object_status: string;
	last_updated: string;
	service: string;
	pop_name: string;
	alerts: string[];
	alarms: string[];
	populated_ports: number[],
	olt_port: string;
	distance: number;
	rx_status: string;
	rx_power: number;
	sfp_status: string;
	sfp_power: number;
	tx_power: number;
	tx_status: string;
	switch: string;
	sfp_sync_speed: string;
	switch_port: number;
}

// SalesForceService Status

export interface ISalesForceServiceStatus {
	status: string;
	overall_status: string;
	device: IDevice;
	parent: IDevice;
	interface: IInterface;
	services: INetworkService[];
	alerts: any[];
	alarms: any[];
	status_reason: null | string;
	access: ISalesForceAccess;
}

export interface IDevice {
	identifier: string;
	reference: string;
	status: string;
	model: null | string;
	name: null | string;
}

export interface IInterface {
	operational_status: string;
	admin_status: string;
	status: string;
	plugged_in: boolean;
	last_uptime: string;
	last_downtime: string;
	ont_distance: number;
	cx_rx_power_level: number;
	cx_rx_low: number;
	cx_rx_high: number;
	cx_rx_status: string;
	pop_tx_power_level: number;
	pop_tx_low: number;
	pop_tx_high: number;
	pop_tx_status: string;
	cx_tx_power_level: number;
	cx_tx_low: number;
	cx_tx_high: number;
	cx_tx_status: string;
	pop_rx_power_level: number;
	pop_rx_low: number;
	pop_rx_high: number;
	pop_rx_status: string;
	name: string;
	type: string;
	reference: string;
	vendor: string;
	network_id: number;
	network_type: string;
	operator_id: number;
	last_seen: string;
	vlan: null | string;
	slan: null | string;
	port: number;
	up_speed: number;
	down_speed: number;
	mac_address: null | string;
	mac_status: null | string;
	object_status: string;
	location: string;
	lease_time: string;
}

export interface INetworkService {
	identifier: string;
	reference: string;
	status: string;
	type: string;
	provider: string;
	up_speed: string;
	down_speed: string;
}

export interface ISalesForceAccess {
	access_gateway: string;
	access_port: string;
	current_session_id: string;
	ip_address: string;
	ip_v6_address: string;
	length_of_current_session: number;
	mac_address: string;
	pppoe_username: string;
}

