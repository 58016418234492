import { appRoute, isNonNull, pathify, Primitive } from '@vumaex/ngx-toolbox';
import { addHours, format } from 'date-fns';
import * as moment from 'moment';
import { GlobalConfig } from 'ngx-toastr';
import { FormAction, StateStatus } from './consts';

interface IServerConfig {
	serverUrl?: string;
	nmsServerUrl?: string;
	sfsServerUrl?: string;
	ispServerUrl?: string;
	ipServerUrl?: string;
	searchServerUrl?: string;
	solarServerUrl?: string;
	supportUrl?: string;
	paymentServerUrl?: string;
	purchaseOrderServerUrl?: string;
	radiusServerUrl?: string;
	workOrderEventUrl?: string;
	eventsUrl?: string;
	billingServiceUrl?: string;
	speedTestUrl?: string;
	assetsServiceUrl?: string;
	securityServiceUrl?: string;
}

export const SERVER_SETTINGS: IServerConfig = {};

export const TOASTER_CONFIG: Partial<GlobalConfig> = {
	positionClass: 'toast-top-center',
	preventDuplicates: true,
};

function baseUrlArray(): Primitive[] {
	return [SERVER_SETTINGS.serverUrl].filter(isNonNull);
}

function speedTestUrlArray(): Primitive[] {
	return [SERVER_SETTINGS.speedTestUrl].filter(isNonNull);
}

function baseIpUrlArray(): Primitive[] {
	return [SERVER_SETTINGS.ipServerUrl].filter(isNonNull);
}

function baseSupportUrlArray(): Primitive[] {
	return [SERVER_SETTINGS.supportUrl].filter(isNonNull);
}

function baseSearchUrlArray(): Primitive[] {
	return [SERVER_SETTINGS.searchServerUrl].filter(isNonNull);
}

function baseSolarUrlArray(): Primitive[] {
	return [SERVER_SETTINGS.solarServerUrl].filter(isNonNull);
}

function basePaymentServerUrlArray(): Primitive[] {
	return [SERVER_SETTINGS.paymentServerUrl].filter(isNonNull);
}

function basePurchaseOrderServerUrlArray(): Primitive[] {
	return [SERVER_SETTINGS.purchaseOrderServerUrl].filter(isNonNull);
}

function baseRadiusServerUrlArray(): Primitive[] {
	return [SERVER_SETTINGS.radiusServerUrl].filter(isNonNull);
}

function baseWorkOrderEventUrlArray(): Primitive[] {
	return [SERVER_SETTINGS.workOrderEventUrl].filter(isNonNull);
}

function baseEventsUrlArray(): Primitive[] {
	return [SERVER_SETTINGS.eventsUrl].filter(isNonNull);
}


function baseBillingServiceUrl(): Primitive[] {
	return [SERVER_SETTINGS.billingServiceUrl].filter(isNonNull);
}

function baseAssetsServiceUrl(): Primitive[] {
	return [SERVER_SETTINGS.assetsServiceUrl].filter(isNonNull);
}

function baseNmsServiceUrl(): Primitive[] {
	return [SERVER_SETTINGS.nmsServerUrl].filter(isNonNull);
}

function baseSalesForceServiceUrl(): Primitive[] {
	return [SERVER_SETTINGS.sfsServerUrl].filter(isNonNull);
}

export function paymentServiceServer(...parts: Primitive[]): string {
	return pathify(...basePaymentServerUrlArray().concat(parts));
}

export function purchaseServiceServer(...parts: Primitive[]): string {
	return pathify(...basePurchaseOrderServerUrlArray().concat(parts));
}

export function radiusServerUrl(...parts: Primitive[]): string {
	return pathify(...baseRadiusServerUrlArray().concat(parts));
}

export function serverUrl(...parts: Primitive[]): string {
	const clearParts = parts.filter(Boolean);
	return pathify(...baseUrlArray().concat(clearParts));
}

export function speedTestServerUrl(...parts: Primitive[]): string {
	const clearParts = parts.filter(Boolean);
	return pathify(...speedTestUrlArray().concat(clearParts));
}

export function maybeSurl(includeServer: boolean, ...parts: Primitive[]): string {
	return includeServer ? serverUrl(...parts) : pathify(...parts);
}

export function ipUrl(...parts: Primitive[]): string {
	const clearParts = parts.filter(Boolean);
	return pathify(...baseIpUrlArray().concat(clearParts));
}

export function searchUrl(...parts: Primitive[]): string {
	const clearParts = parts.filter(Boolean);
	return pathify(...baseSearchUrlArray().concat(clearParts));
}

export function solarUrl(...parts: Primitive[]): string {
	const clearParts = parts.filter(Boolean);
	return pathify(...baseSolarUrlArray().concat(clearParts));
}

export function supportUrl(...parts: Primitive[]): string {
	return pathify(...baseSupportUrlArray().concat(parts));
}

export function workOrderEventUrl(...parts: Primitive[]): string {
	const clearParts = parts.filter(Boolean);
	return pathify(...baseWorkOrderEventUrlArray().concat(clearParts));
}

export function eventsUrl(...parts: Primitive[]): string {
	const clearParts = parts.filter(Boolean);
	return pathify(...baseEventsUrlArray().concat(clearParts));
}

export function billingServiceUrl(...parts: Primitive[]): string {
	return pathify(...baseBillingServiceUrl().concat(parts));
}

export function assetsServerUrl(...parts: Primitive[]): string {
	return pathify(...baseAssetsServiceUrl().concat(parts));
}

export function nmsServerUrl(...parts: Primitive[]): string {
	return pathify(...baseNmsServiceUrl().concat(parts));
}

export function salesForceServiceUrl(...parts: Primitive[]): string {
	return pathify(...baseSalesForceServiceUrl().concat(parts));
}

export const BASE_ROUTES = {
	assets: appRoute('assets'),
	billing: appRoute('billing'),
	boltOnServices: appRoute('bolt-on-services'),
	civils: appRoute('civils-pmo'),
	deviceManagement: appRoute('device-management'),
	interconnectsManagement: appRoute('interconnects-management'),
	ipPools: appRoute('ip-pools'),
	ispArea: appRoute('isp-area'),
	payments: appRoute('payments'),
	reach: appRoute('reach'),
	reportingGrids: appRoute('reporting'),
	rica: appRoute('rica'),
	searchGrids: appRoute('search'),
	serviceStatus: appRoute('service-status'),
	scheduler: appRoute('scheduler'),
	speedTest: appRoute('speed-test'),
	support: appRoute('support'),
	vlans: appRoute('vlans'),
	voip: appRoute('voip'),
	workOrder: appRoute('work-order'),
	billingReport: appRoute('billing-report'),
	workOrderEvent: appRoute('work-order-event'),
	serviceConfiguration: appRoute('service-configuration'),
};

export const BOLT_ROUTES = {
	boltOn: appRoute(':id'),
};

export const ASSET_ROUTES = {
	serviceAssets: appRoute('service/:id'),
	workOrderAssets: appRoute('workorder/:id'),
};

export const BILLING_ROUTES = {
	invoice: appRoute(':id'),
	entityInvoice: appRoute('entity/:id'),
	providerInvoice: appRoute('provider/:id'),
	providers: appRoute('providers'),
	workOrderInvoice: appRoute('workorder/:id'),
	priceOverride: appRoute('price-override/:id'),
};

export const DEVICE_MANAGEMENT_ROUTES = {
	l3: appRoute('l3/:id'),
	device: appRoute(':id'),
};

export const PMO_ROUTES = {
	absoluteAreas: appRoute(`${ BASE_ROUTES.civils.name }/areas`),
	coverage: appRoute('coverage'),
	provider: appRoute('provider'),
	region: appRoute('region'),
	process: appRoute('process'),
	areas: appRoute('areas'),
	area: appRoute('areas/:id'),
	premiseAdmin: appRoute('premise-admin'),
	ticketing: appRoute('ticketing'),
	areaSummary: appRoute('area-summary'),
};

export const GRID_ROUTES = {
	workOrdersGrid: appRoute('workorders'),
	servicesGrid: appRoute('services'),
};

export const INTERCONNECT_ROUTES = {
	coverageRegions: appRoute('coverageRegion'),
	providerCoverages: appRoute('providerCoverages'),
	regionOlts: appRoute('regionOlts'),
};

export const IP_ROUTES = {
	login: appRoute('login'),
	ipPools: appRoute('dashboard'),
};

export const ISP_AREA_MANAGEMENT_ROUTES = {
	ispAreaManagement: appRoute(''),
};

export const PAYMENTS_ROUTES = {
	payment: appRoute('payment-history/:serviceId'),
	adjustments: appRoute(':serviceId'),
	adjustment: appRoute('adjustment/:serviceId'),
};

export const REACH_ROUTES = {
	reach: appRoute(':id'),
};

export const RICA_ROUTES = {
	rica: appRoute('rica/:id'),
};

export const SERVICE_STATUS_ROUTES = {
	solar: appRoute('solar/:id'),
	ont: appRoute('ont/:id'),
	noOnt: appRoute('ont'),
};

export const SUPPORT_ROUTES = {
	history: appRoute(':id'),
};

export const SPEEDTEST_ROUTES = {
	speedTest: appRoute(':id'),
	speedTestHistory: appRoute('history/:id'),
	speedTestLanding: appRoute('landing/:id'),
};

export const VLAN_ROUTES = {
	vlans: appRoute(':id'),
};

export const VOIP_ROUTES = {
	voip: appRoute(':id'),
};

export const WORKORDER_ROUTES = {
	workOrder: appRoute(':id'),
	workOrderDocument: appRoute('documents/:reference'),
};

export const WORKORDEREVENT_ROUTES = {
	workOrderRules: appRoute('rules'),
};

export const BOLT_ON_SERVICES_ROUTES = {
	index: appRoute('index'),
};

export const BILLING_REPORT_ROUTES = {
	billingReport: appRoute(''),
};

export const SERVICE_CONFIGURATION_ROUTES = {
	configurationMethod: appRoute(':id'),
};


export class Utils {
	private static readonly dateFormat = 'yyyy-MM-dd';
	private static readonly timeFormat = 'HH:mm:ss';
	private static readonly defaultTime = '08:00:00';
	private static readonly MAX_DATE_SIZE = 10;
	private static readonly MAX_TIME_SIZE = 8;

	public static getStartTime(date: string, time: string): Date {
		if (Utils.isEmpty(date))
			return null;

		const _time = Utils.isEmpty(time) ? Utils.defaultTime : time;

		if (date.length > Utils.MAX_DATE_SIZE)
			Utils.formatDate(date);

		if (time.length > Utils.MAX_TIME_SIZE)
			Utils.formatTime(time);

		return new Date(`${ date } ${ _time }`);
	}

	public static getEndTime(dtime: Date, duration: number = 2): Date {
		if (Utils.isEmpty(dtime))
			return null;
		return addHours(dtime, duration);
	}

	public static isEmpty(value: any): boolean {
		return value === null || value === undefined || value === '';
	}

	public static exists(array: any[], value: any): any {
		return array.findIndex(i => i === value) !== -1;
	}

	public static formatDate(date: string): string {
		if (Utils.isEmpty(date))
			return null;
		return format(new Date(date), Utils.dateFormat);
	}

	public static formatTime(time: string): string {
		if (Utils.isEmpty(time))
			return null;
		return format(new Date(time), Utils.timeFormat);
	}

	public static isNullOrUndefined(value: any): boolean {
		return value === null || value === undefined;
	}

	public static parameter(name: string): string {
		const url = window.location.href;
		const REGEX_ARRAY_SIZE = 2;
		const _name = name.replace(/[\[\]]/g, '\\$&');
		const regex = new RegExp('[?&]' + _name + '(=([^&#]*)|&|#|$)');
		const results = regex.exec(url);
		if (!results || !results[REGEX_ARRAY_SIZE])
			return '';

		return decodeURIComponent(results[REGEX_ARRAY_SIZE].replace(/\+/g, ' '));
	}

	public static get searchConfig(): any {
		return {
			displayKey: 'name',
			search: true,
			height: 'auto',
			placeholder: 'Start typing to search',
			limitTo: 5,
			moreText: 'more',
			noResultsFound: 'No results found!',
			searchPlaceholder: 'Start typing to search',
			searchOnKey: 'name',
		};
	}

	public static getSetting(value: any, defaultValue: any): any {
		return Utils.isEmpty(value) ? defaultValue : value;
	}

	public static getStatusColor(status: string): string {
		switch (status) {
			case StateStatus.NOT_STARTED:
				return 'badge-light';
			case StateStatus.IN_PROGRESS:
				return 'badge-info';
			case StateStatus.ON_HOLD:
				return 'badge-warning';
			case StateStatus.COMPLETE:
				return 'badge-success';
			default:
				return 'badge-dark';
		}
	}

	public static dateDiff(start: string, end: string): number {
		const startDate = moment(start);
		const endDate = moment(end);
		return startDate.diff(endDate, 'days');
	}

	public static getStatusMessage(action: FormAction, success: boolean, name: string): string {
		switch (action) {
			case FormAction.EDIT:
				if (success)
					return `Success : ${ name } updated`;
				return `Error : ${ name } update failed`;
			case FormAction.CREATE:
				if (success)
					return `Success : ${ name } created`;
				return `Error : ${ name } creation failed`;
			default:
				return 'Unknown: An unknown action ';
		}
	}

	public static numbersOnly(value: any): number {
		if (typeof (value) === 'number')
			return value;
		return null;
	}

	public static bearer(token: string): string {
		return `Bearer ${token}`;
	}

	public static compare<T>(a: T, b: T): number {
		// tslint:disable-next-line: strict-comparisons
		if (a === b) return 0;
		if (a === null) return -1;
		if (b === null) return 1;

		// To appease the type system, check both
		if (typeof a === 'string' && typeof b === 'string')
			return a.localeCompare(b, 'standard', { sensitivity: 'case' });

		// tslint:disable-next-line: strict-comparisons
		return b > a ? -1 : 1;
	}
}

